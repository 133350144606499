import React, { useMemo, useState } from "react";
import { styled } from "goober";

import { readPath } from "@/__main__/get-data.mjs";
import { appURLs } from "@/app/constants.mjs";
import { GAME_SYMBOL_LOL } from "@/game-lol/definition-symbol.mjs";
import { useOverlaySettings } from "@/library/actions.mjs";
import { useSimulationEngine } from "@/library/hooks.mjs";
import useHotkey from "@/util/use-hotkey.mjs";

const SIMULATED_JUNGLE_TIMER_CONFIG = {
  selectKey: ["isSummonersRiftJungleOverlayAlwaysVisible"],
  features: [
    {
      simulationSpec: {
        minutes: 3,
        seconds: 15,
        type: "timer",
        direction: "down",
      },
      top: "70px",
      left: "250px",
    },
    {
      simulationSpec: {
        minutes: 4,
        seconds: 48,
        type: "timer",
        direction: "down",
      },
      top: "98px",
      left: "104px",
    },
    {
      simulationSpec: {
        minutes: 1,
        seconds: 33,
        type: "timer",
        direction: "down",
      },
      top: "151px",
      left: "81px",
    },
    {
      simulationSpec: {
        minutes: 1,
        seconds: 24,
        type: "timer",
        direction: "down",
      },
      top: "235px",
      left: "166px",
    },
    {
      simulationSpec: {
        minutes: 5,
        seconds: 12,
        type: "timer",
        direction: "down",
      },
      top: "228px",
      left: "213px",
    },
    {
      simulationSpec: {
        minutes: 1,
        seconds: 48,
        type: "timer",
        direction: "down",
      },
      top: "175px",
      left: "239px",
    },
  ],
} as const;

const SimulatedJungleTimersOverlay = () => {
  const [show, setShow] = useState(true);
  const settings = useOverlaySettings(GAME_SYMBOL_LOL);
  const simulationConfig = useMemo(() => SIMULATED_JUNGLE_TIMER_CONFIG, []);

  const displayOption = useMemo(() => {
    if (!settings || !simulationConfig) return;
    const option = readPath(simulationConfig.selectKey, settings);

    // change visibility of overlay based on option setting
    setShow(option);

    return option;
  }, [settings, simulationConfig]);

  useHotkey(
    "Tab",
    (e) => {
      e?.preventDefault();
      if (displayOption !== true) setShow((show) => !show);
    },
    [displayOption],
  );

  const { simulatedValues } = useSimulationEngine({
    simulationSchema: simulationConfig.features.reduce((acc, feature, idx) => {
      return {
        ...acc,
        [idx]: {
          ...feature.simulationSpec,
        },
      };
    }, {}),
    tickIntervalInMs: 1000,
    numberOfTicks: 10,
  });

  const data = useMemo(() => {
    if (!settings) return;
    if (!simulatedValues) return;
    return {
      features: Object.values(simulationConfig.features).map((feature, idx) => {
        return {
          ...feature,
          value: simulatedValues[idx],
        };
      }),
    };
  }, [settings, simulatedValues, simulationConfig.features]);

  if (!data) return;

  return (
    <OverlayContainer>
      <div className="relative">
        <img
          src={`${appURLs.CDN}/blitz/ui/img/settings/lolOverlay/jungleTimers/minimap.webp`}
          alt="lol-minimap"
        />
        {show &&
          data.features.map(({ value, top, left }, idx) => {
            return <Timer value={value} top={top} left={left} key={idx} />;
          })}
      </div>
    </OverlayContainer>
  );
};

export default SimulatedJungleTimersOverlay;

interface TimerProps {
  value: number;
  top: string;
  left: string;
}

const Timer = ({ value, top, left }: TimerProps) => {
  const minutes = Math.floor(value / 60);
  const seconds = value % 60;
  const timerText = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;

  return <OverlayText style={{ top, left }}>{timerText}</OverlayText>;
};

const OverlayContainer = styled("div")`
  padding: var(--sp-5) 0;
  width: auto;
`;

const OverlayText = styled("span")`
  position: absolute;

  font-size: var(--sp-4);
  font-style: normal;
  line-height: var(--sp-5);

  color: var(--shade0);
  text-shadow: 0 1px 4px var(--shade10);
`;
